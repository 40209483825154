<template>
  <div>
    <topics-list-add-new
      :is-add-new-topic-sidebar-active.sync="isAddNewTopicSidebarActive"
    />

    <topics-list-edit
      :is-edit-topic-sidebar-active.sync="isEditTopicSidebarActive"
      :topic.sync="editTopic"
      @on-cancel="updateTopicsList"
    />

    <b-card
      class="mb-0"
    >
      <b-row class="mb-2 pb-2 border-bottom">
        <b-col>
          <b-button
            variant="primary"
            @click="isAddNewTopicSidebarActive = true"
          >
            <span class="text-nowrap">Add Topic</span>
          </b-button>
        </b-col>
      </b-row>

      <b-row
        v-for="topic in topics"
        :key="topic.id"
        class="mb-1 pb-1 border-bottom"
      >
        <b-col>
          {{ topic.name }}
        </b-col>

        <b-col class="text-right">
          <b-button-group
            size="sm"
          >
            <b-button
              variant="outline-primary"
              @click="isEditTopicSidebarActive = true; editTopic = topic;"
            >
              Edit
            </b-button>
            <b-button
              variant="outline-warning"
              @click="openDeleteModal(topic, deleteTargetItem)"
            >
              Delete
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col>
          <b-input-group>
            <b-form-input value="Example" />
            <b-input-group-append>
              <b-button variant="outline-secondary">
                Cancel
              </b-button>
              <b-button variant="outline-primary">
                Save
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row> -->

      <!-- TODO: have jsut topic names listed, with edit / delete (with confirm) buuttons.
      When clicking edit, convert text to inputbox, with save/cancel buttons grouped together. Needs validation
       add new topic button at bottom -->

    </b-card>

    <b-modal id="modalDelete">
      <template #modal-header="{}">
        <h5>Confirm deletion</h5>
      </template>

      Are you sure you want to delete the topic '{{ deleteTargetItem.name }}'?

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="primary"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="deleteTarget(deleteTargetItem.id); cancel();"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BButtonGroup, BModal,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import TopicsListAddNew from './TopicsListAddNew.vue'
import TopicsListEdit from './TopicsListEdit.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BModal,
    TopicsListAddNew,
    TopicsListEdit,
  },
  methods: {
    openDeleteModal(item, targetRef) {
      // Update modal data then open it
      targetRef.id = item.id // eslint-disable-line no-param-reassign
      targetRef.name = item.name // eslint-disable-line no-param-reassign
      this.$bvModal.show('modalDelete')
    },
    updateTopicsList() {
      this.getListData()
    },
  },
  setup() {
    const topics = ref([])
    // Use toast
    const toast = useToast()

    const isAddNewTopicSidebarActive = ref(false)
    const isEditTopicSidebarActive = ref(false)
    const editTopic = ref({})

    const deleteTargetItem = ref({ id: null, name: null })
    const deleteTarget = targetId => {
      store.dispatch('app/deleteTopic', { id: targetId })
        .then(() => {
          // remove object form array by ID
          topics.value.splice(topics.value.findIndex(topic => topic.id === targetId), 1)
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting topic.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const getListData = () => {
      store.dispatch('app/fetchTopics', { perPage: 1000, sortBy: 'name' })
        .then(response => {
          topics.value = response.data.results
        })
        .catch(error => {
          if (error.response.status && error.response.status === 404) {
            topics.value = undefined
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error getting Topics.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // call getListData on component load
    getListData()

    return {
      // Sidebar
      isAddNewTopicSidebarActive,
      isEditTopicSidebarActive,
      editTopic,
      topics,
      deleteTargetItem,
      deleteTarget,
      getListData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
