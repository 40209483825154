<template>
  <b-sidebar
    id="edit-topic-sidebar"
    :visible="isEditTopicSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-topic-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Topic
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required|min:3|max:100"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="$props.topic.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide(); $emit('on-cancel')"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditTopicSidebarActive',
    event: 'update:is-edit-topic-sidebar-active',
  },
  props: {
    isEditTopicSidebarActive: {
      type: Boolean,
      required: true,
    },
    topic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const onSubmit = () => {
      store.dispatch('app/updateTopic', { id: props.topic.id, topicData: props.topic })
        .then(() => {
          emit('update:is-edit-topic-sidebar-active', false)
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error editing topic.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    return {
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-topic-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
